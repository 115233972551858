@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;700&display=swap');

@font-face {
  font-family: 'Gemunu Libre';
  src: url('./gemunu-libre.extrabold.otf') format('opentype');
  font-weight: 800;
}
@font-face {
  font-family: 'Catv 6x12 9';
  src: url('./CatV_6x12_9.ttf') format('opentype');
  font-weight: 800;
}
* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
  width: 100%;
}

body {
  overflow-x: clip !important;
  margin: 0;
  min-height: 100%;
  background-color: #fff;
  font-family: Arial, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #333;
  display: block;
  overflow: visible;
  font-family: 'Work Sans', sans-serif;
}

#dh-slide-puzzle {
  font-family: 'Gemunu Libre', sans-serif;
  font-size: 192px;
  position: relative;
}

#dh-slide-puzzle .dh-slide-letter {
  position: absolute;
  line-height: 0.8;
  transition: transform ease-in-out 200ms;
}
